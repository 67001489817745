body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5; /* Fundo do body */
    color: #333;
    line-height: 1.6;
}

.galeria-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
    text-align: center;
    width: 100%;
    margin-bottom: 15rem; /* Adicionando margem inferior */
}


.fab-container {
    position: fixed;
    right: 25px;
    bottom: 25px;
    z-index: 1000;
}

.galeria-paper {
    padding: 20px;
    max-width: 800px;
    width: 100%;
    background-color: 'transparent'; /* Fundo transparente */
    box-shadow: none; /* Nenhuma sombra */
}

.galeria-paper .text-center {
    color: #000;
    font-size: 1.5em;
    margin-bottom: 16px;
    font-weight: bold;
}

.galeria-paper .mb-4 {
    margin-bottom: 16px !important;
}

.galeria-item-grid {
    text-align: left;
    background-color: white;
    padding: 15px; /* Reduz o espaçamento interno para compactar o item */
    border-radius: 8px; /* Adiciona cantos arredondados */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    margin-top: 5px; /* Reduz ainda mais o espaçamento superior */
    height: auto; /* Ajusta a altura automaticamente com base no conteúdo */
    flex-basis: 100%; /* Inicialmente ocupa toda a largura disponível */
    flex-grow: 1; /* Permite que os itens cresçam para preencher o espaço */
    min-width: 300px; /* Define uma largura mínima */
    max-width: 800px; /* Define uma largura máxima */

}

.dialog-paper {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.dialog-button {
    background-color: #f44336; /* Red */
    color: white;
    transition: background-color 0.3s ease;
}

.dialog-button:hover {
    background-color: #d32f2f; /* Darker Red */
}


.galeria-grid {
    display: flex;
    flex-wrap: wrap;
    height: auto; /* Ajusta a altura automaticamente com base no conteúdo */
    padding-top: 3%; /* Reduz o espaço superior */
    justify-content: center;
    gap: 20px; /* Espaçamento entre os itens */
    margin-bottom: 7rem; /* Adicionando margem inferior */

}

.galeria-item {
    padding: 20px;
    color: #000;
    border-radius: 5px;
    background-color: 'transparent'; /* Fundo transparente */
    box-shadow: none; /* Nenhuma sombra */
}

.fab-container {
    position: fixed;
    right: 25px;
    bottom: 25px;
    z-index: 1000;
}

.fab-container .MuiFab-root {
    background-color: #3f51b5;
    color: #ffffff;
    transition: background-color 0.3s ease;
}

.fab-container .MuiFab-root:hover {
    background-color: #273270;
}

.details-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999; /* Garante que o detalhe seja exibido acima de outros elementos */
    background-color: rgba(0, 0, 0, 0.5); /* Cor de fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Media Queries para diferentes tamanhos de tela */
@media (min-width: 600px) {
    .galeria-item-grid {
        flex-basis: 48%; /* Ocupa quase metade da largura em telas médias */
    }
}

@media (min-width: 900px) {
    .galeria-item-grid {
        flex-basis: 32%; /* Ocupa cerca de um terço da largura em telas grandes */
    }
}
