.signInContainer {
    margin: auto;
    width: 80%;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .signInContainer .MuiTextField-root {
    margin-bottom: 15px;
  }
  
  .signInContainer .MuiButton-root {
    margin-top: 20px;
    background-color: #3f51b5; /* Mesma cor usada no AppBar */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .signInContainer .MuiButton-root:hover {
    background-color: #1a237e; /* Tom de azul mais escuro */
  }
  