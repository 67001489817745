/* Estilos para dispositivos com largura menor ou igual a 600px (celulares) */
@media (max-width: 600px) {
  .details-screen-container {
    width: 100%; /* Definir a largura como 100% para ocupar toda a largura da tela */
    padding: 10px; /* Reduzir o preenchimento para melhorar o aproveitamento do espaço */
  }
  
  .details-screen-container h2 {
    font-size: 1.5rem; /* Reduzir o tamanho da fonte do cabeçalho */
  }
}

/* Estilos para dispositivos com largura menor ou igual a 400px (celulares em modo paisagem ou dispositivos muito pequenos) */
@media (max-width: 400px) {
  .details-screen-container h2 {
    font-size: 1.2rem; /* Reduzir ainda mais o tamanho da fonte do cabeçalho */
  }
}
