body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    line-height: 1.6;
  }
  
  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
    text-align: center;
    width: 100%;
    margin-bottom: 15rem;
  }
  
  .home-paper {
    padding: 20px;
    max-width: 800px;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
  }
  
  .home-paper .text-center {
    color: #000;
    font-size: 1.5em;
    margin-bottom: 16px;
    font-weight: bold;
  }
  
  .home-paper .mb-4 {
    margin-bottom: 16px !important;
  }
  
  .home-item-grid {
    width: 100%;
    text-align: left;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    height: auto;
    flex-basis: 100%;
    flex-grow: 1;
  }
  
  .dialog-paper {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .dialog-button {
    background-color: #f44336;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .dialog-button:hover {
    background-color: #d32f2f;
  }
  
  .home-grid {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    padding-top: 3%;
    justify-content: center;
    gap: 20px;
    margin-bottom: 7rem;
  }
  
  .home-item {
    padding: 20px;
    color: #000;
    border-radius: 5px;
    background-color: transparent;
    box-shadow: none;
  }
  
  .details-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .imageList {
    width: 500;
    height: 100%;
    transform: translateZ(0);
  }
  
  .titleBar {
    background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%);
  }
  
  .icon {
    color: white;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Media Queries para responsividade */
  @media (max-width: 768px) {
    .home-container {
      padding: 10px;
      margin-bottom: 10rem;
    }
  
    .home-paper .text-center {
      font-size: 1.2em;
    }
  
    .home-item-grid {
      padding: 10px;
    }
  
    .home-grid {
      gap: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .home-paper .text-center {
      font-size: 1em;
    }
  
    .home-item-grid {
      padding: 5px;
    }
  
    .home-grid {
      gap: 5px;
    }
  }
  