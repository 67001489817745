/* Estilos para o diálogo de detalhes do usuário */
.details-dialog {
    padding: 16px;
}

/* Estilos para o conteúdo do diálogo */
.details-dialog-content {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
}

/* Estilos para o avatar no diálogo */
.details-dialog-avatar {
    justify-content: center;
    display: flex;
    align-items: center;
}

/* Estilos para os botões no diálogo */
.details-dialog-button {
    width: 130px;
    border-radius: 5px;
    margin-right: 8px;
    margin-bottom: 8px;
}

/* Estilos para o botão "Salvar" */
.details-dialog-button-save {
    background-color: #4caf50;
    color: white;
}

/* Estilos para o botão "Excluir" */
.details-dialog-button-delete {
    background-color: #f44336;
    color: white;
}
