body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5; /* Fundo do body */
    color: #333;
    line-height: 1.6;
}

.servico-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
    text-align: center;
    width: 100%;
    margin-bottom: 15rem; /* Adicionando margem inferior */
}



.servico-paper {
    padding: 20px;
    max-width: 800px;
    width: 100%;
    background-color: transparent; /* Fundo transparente */
    box-shadow: none; /* Nenhuma sombra */
}

.servico-paper .text-center {
    color: #000;
    font-size: 1.5em;
    margin-bottom: 16px;
    font-weight: bold;
}

.servico-paper .mb-4 {
    margin-bottom: 16px !important;
}

.servico-item-grid {
    text-align: left;
    background-color: white;
    padding: 15px; /* Reduz o espaçamento interno para compactar o item */
    border-radius: 8px; /* Adiciona cantos arredondados */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    height: auto; /* Ajusta a altura automaticamente com base no conteúdo */
    flex-basis: 100%; /* Inicialmente ocupa toda a largura disponível */
    flex-grow: 1; /* Permite que os itens cresçam para preencher o espaço */
    min-width: 300px; /* Define uma largura mínima */
    max-width: 800px; /* Define uma largura máxima */
}

.dialog-paper {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.dialog-button {
    background-color: #f44336; /* Red */
    color: white;
    transition: background-color 0.3s ease;
}

.dialog-button:hover {
    background-color: #d32f2f; /* Darker Red */
}

.servico-grid {
    display: flex;
    flex-wrap: wrap;
    height: auto; /* Ajusta a altura automaticamente com base no conteúdo */
    padding-top: 3%; /* Reduz o espaço superior */
    justify-content: center;
    gap: 20px; /* Espaçamento entre os itens */
    margin-bottom: 7rem; /* Adicionando margem inferior */
}

.servico-item {
    padding: 20px;
    color: #000;
    border-radius: 5px;
    background-color: transparent; /* Fundo transparente */
    box-shadow: none; /* Nenhuma sombra */
}

.details-screen-overlay {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999; /* Garante que o detalhe seja exibido acima de outros elementos */
    background-color: rgba(0, 0, 0, 0.5); /* Cor de fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Estilos para dispositivos com largura menor ou igual a 600px (celulares) */
@media (max-width: 600px) {
    .details-screen-container {
      width: 90%; /* Definir a largura como 90% para ocupar a maior parte da largura da tela */
      padding: 10px; /* Reduzir o preenchimento para melhorar o aproveitamento do espaço */
    }
    
    .details-screen-container h2 {
      font-size: 1.5rem; /* Reduzir o tamanho da fonte do cabeçalho */
    }
  }
  
  /* Estilos para dispositivos com largura menor ou igual a 400px (celulares em modo paisagem ou dispositivos muito pequenos) */
  @media (max-width: 400px) {
    .details-screen-container {
      width: 100%; /* Definir a largura como 100% para ocupar toda a largura da tela */
    }
  
    .details-screen-container h2 {
      font-size: 1.2rem; /* Reduzir ainda mais o tamanho da fonte do cabeçalho */
    }
  }
  