/* Reset de estilos padrão */
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5; /* Fundo do body */
    color: #333;
    line-height: 1.6;
}

.user-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.user-list-item {
    background-color: white;
    padding: 15px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.user-item-list {
    padding-right: 1rem;
}
.user-list-item:hover {
background-color: #a6a5a5;
}

.user-list-title {
    margin-bottom: 10px;
}

.truncated-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  


/* Estilo para o título do item */
.user-list-item h2 {
    font-size: 1.5em;
    margin-bottom: 16px;
    font-weight: bold;
}

/* Estilo para a descrição do item */
.user-list-item p {
    margin-bottom: 16px;
}

/* Estilo para o botão de detalhes */
.user-list-item .details-button {
    background-color: #00796b;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Efeito hover para o botão de detalhes */
.user-list-item .details-button:hover {
    background-color: #3f51b5;
}

/* Estilo para o campo de busca */
.search-input {
    width: 80%; /* Reduzindo o tamanho para 80% */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    margin: 0 auto; /* Alinhando ao centro */
    margin-bottom: 10px; /* Adicionando espaço em baixo */
    margin-left: 15%;
}

  /* Estilo para o seletor de filtro */
  .filter-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  